import ToastHelper, { STATUS_HELPER } from "../helpers/ToastHelper";
import isNetworkErrorValidation from "../utils/isNetworkErrorValidation";

const toastHelper = new ToastHelper()

export class GenericAPI {
  static _exception(e, message) {
    const isNetworkError = isNetworkErrorValidation(e)

    const statusToast = isNetworkError ? STATUS_HELPER.DISMISSABLE : STATUS_HELPER.ERROR;
    const messageToast = isNetworkError ? 'Sem internet, entre em contato com a administração do Locker' : message;

    toastHelper.notify(statusToast, messageToast, 5000)
    return { error: messageToast, isNetworkError };
  }
}


const NUMBER_OF_TRIES = 3

export const axiosRetryConfig = {
  retries: NUMBER_OF_TRIES,
  retryDelay: (retryCount) => {
    return retryCount * 10000;
  },
  retryCondition: isNetworkErrorValidation,
  onRetry: (retryCount, error, requestConfig) => {
    console.log(`Retry count: ${retryCount} - URL: ${requestConfig.url}`);
    toastHelper.notify(STATUS_HELPER.ERROR, `Sem internet. Realizando nova tentativa de conexão: ${retryCount} de ${NUMBER_OF_TRIES}`, retryCount * 10000);
  }
}