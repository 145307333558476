import BaseModel from "./BaseModel";

class ContainerMeasureModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.name = data.name;
    this.height = data.height;
    this.width = data.width;
    this.depth = data.depth;
    this.dimensionText = (<>{this.name}<br />  A: {data.height} x  L: {data.width} x  P: {data.depth}</>)
    this.hasAvailableContainers = data.availableCount > 0;
    this.availableCount = data.availableCount;
  }

}

export default ContainerMeasureModel;
