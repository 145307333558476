import BaseAPI from "./BaseAPI";
import WebhookAPI from "./WebhookAPI";
import { URLS } from "./Endpoints";
import { GenericAPI } from "../services/GenericAPI"


class LockerAPI extends GenericAPI {

  /**
   * Request que busca locker por uuid
   * @param  {string} uuid - uuid do locker
   */
  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.LOCKER}/${uuid}/preview`);
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao buscar locker.");
    } catch (e) {
      return this._exception(e, "Falha ao buscar locker.");
    }
  }

  /**
   * Request que busca módulos de locker por uuid
   * @param  {string} uuid - uuid do locker
   */
  static async getModules(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.LOCKER}/${uuid}/modules`);
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao buscar módulos de locker.");
    } catch (e) {
      return this._exception(e, "Falha ao buscar módulos locker.");
    }
  }

  /**
   * Request que busca locker por uuid
   * @param  {string} uuid - uuid do usuário
   */
  static async getFeatures(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.LOCKER}/${uuid}/features`);
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao buscar locker.")
    } catch (e) {
      return this._exception(e, "Falha ao buscar locker.");
    }
  }

  /**
   * Request que busca locker por uuid
   * @param  {string} uuid - uuid do usuário
   */
  static async getAvailableContainerTypes(uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.LOCKER}/${uuid}/available-containers-types`
      );
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao buscar compartimentos disponiveis.");
    } catch (e) {
      return this._exception(e, "Falha ao buscar compartimentos disponiveis.");
    }
  }

  /**
   * Request que busca locker por uuid
   * @param  {string} uuidLocker - uuid do locker
   * @param  {string} uuidContainerType - uuid do container type
   *
   */
  static async getAvailableContainerMeasures(uuidLocker, uuidContainerType) {
    try {
      const response = await BaseAPI.get(
        `${URLS.LOCKER}/${uuidLocker}/type/${uuidContainerType}/available-containers-measures`
      );
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao buscar compartimentos disponiveis.");
    } catch (e) {
      return this._exception(e, "Falha ao buscar compartimentos disponiveis.");
    }
  }

  /**
   * Request que busca containers disponiveis em um locker,
   * definindo o type e measure do container
   * @param  {string} uuidLocker - uuid do locker
   * @param  {string} uuidContainerType - uuid do container type
   * @param  {string} uuidContainerMeasure - uuid do container measure
   *
   */
  static async getAvailableContainersByTypeAndMeasure(
    uuidLocker,
    uuidContainerType,
    uuidContainerMeasure
  ) {
    try {
      const response = await BaseAPI.get(
        `${URLS.LOCKER}/${uuidLocker}/type/${uuidContainerType}/measure/${uuidContainerMeasure}/available-containers`
      );
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao buscar compartimentos disponiveis.");
    } catch (e) {
      return this._exception(e, "Falha ao buscar compartimentos disponiveis.");
    }
  }

  /**
   * Request que notifica API que lcoker está online
   * @param  {string} uuidLocker - uuid do locker
   *
   */
  static async onlineLockerWebhook(
    uuidLocker,
  ) {
    try {
      const response = await WebhookAPI.post(
        `${URLS.WEBHOOKLOGGER}`,
        { uuidLocker }
      );
      return response.status === 200;
    } catch (e) {
      return false;
    }
  }

}

export default LockerAPI;
