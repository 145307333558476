const VERSION = "/v1";

export const URLS = {
  VERSION,
  USER: `${VERSION}/user`,
  COMPANY: `${VERSION}/company`,
  ORGANIZATION: `${VERSION}/company/organization`,
  LOCKER: `${VERSION}/locker`,
  FLOW: `${VERSION}/flow`,
  ALLOCATION: `${VERSION}/allocation`,
  WEBHOOKLOGGER: `${VERSION}/integrations/webhook-logger`,
};

const EVENTS = "/events"
export const URLSLOCALDB = {
  BLOCK_COMPARTMENTS: `${EVENTS}/block-compartments`,
  UNLOCK_COMPARTMENTS: `${EVENTS}/block-compartments`,
  END_SESSION: `${EVENTS}/end-session`,
  TOTAL_UNAVAILABILITY: `${EVENTS}/total-unavailability`,
  SIZE_UNAVAILABILITY: `${EVENTS}/size-unavailability`,
  USER_LOGIN: `${EVENTS}/user-login`,
  RETRIEVE_ORDER: `${EVENTS}/retrieve-order`,
  CHANGE_CLOCK: `${EVENTS}/change-clock`,
  EQUIPMENT_AVAILABLE: `${EVENTS}/equipment-available`,
  SUPPLY_FAILURE: `${EVENTS}/supply-failure`,
  UNBLOCK_COMPARTMENTS_BY_FAILURE: `${EVENTS}/unblock-compartments-by-failure`,
  EVENTS: `${EVENTS}`,
  TOKEN: `/auth/token`,
};

//unblock-compartments-by-failure
