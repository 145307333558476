import axios from "axios";
import config from "../config";
import { URLSLOCALDB } from "./Endpoints";
import { KEYS } from "../helpers/utils/StorageUtil";

/* 
  Integração com a API para o registro de logs no banco de dados local.
  As ações que serão resgistradas estão especificadas abaixo.
  Também será feita uma sincronização com o banco exetreno em intervalos 
  de tempo determinados.
*/


export default class LocalDBAPI {
  // Registra o evento de encerramento de sessão (fim do abastecimento)
  static async endSession() {
    const registration_code = localStorage.getItem(KEYS.CORREIOS_SEPARATOR_REGISTRATION);
    const locker_uuid = localStorage.getItem(KEYS.LOCKER_UUID);
    await api.post(`${URLSLOCALDB.END_SESSION}`, {
      locker_uuid,
      registration_code,
    });
    localStorage.removeItem(KEYS.CORREIOS_SEPARATOR_REGISTRATION)
  }

  // 	Registra o evento de indisponibilidade total (do tipo de container)
  static async totalUnavailability() {
    const registration_code = localStorage.getItem(KEYS.CORREIOS_SEPARATOR_REGISTRATION);
    const locker_uuid = localStorage.getItem(KEYS.LOCKER_UUID);
    await api.post(`${URLSLOCALDB.TOTAL_UNAVAILABILITY}`, {
      locker_uuid,
      registration_code,
    });
  }

  // Registra o evento de tamanho indisponível
  static async sizeUnavailability() {
    const registration_code = localStorage.getItem(KEYS.CORREIOS_SEPARATOR_REGISTRATION);
    const locker_uuid = localStorage.getItem(KEYS.LOCKER_UUID);
    await api.post(`${URLSLOCALDB.SIZE_UNAVAILABILITY}`, {
      locker_uuid,
      registration_code,
    });
  }

  // Registra o evento de login de usuário (autenticação do QR code ou senha)
  static async userLogin() {
    const registration_code = localStorage.getItem(KEYS.CORREIOS_SEPARATOR_REGISTRATION);
    const locker_uuid = localStorage.getItem(KEYS.LOCKER_UUID);
    await api.post(`${URLSLOCALDB.USER_LOGIN}`, {
      locker_uuid,
      registration_code,
    });
  }

  // Registra o evento de retirada de encomenda
  static async retrieveOrder(
    container_uuid,
    allocation_uuid,
  ) {
    const registration_code = localStorage.getItem(KEYS.CORREIOS_SEPARATOR_REGISTRATION);
    const locker_uuid = localStorage.getItem(KEYS.LOCKER_UUID);
    await api.post(`${URLSLOCALDB.RETRIEVE_ORDER}`, {
      locker_uuid,
      registration_code,
      container_uuid,
      allocation_uuid,
    });
  }

  // Registra o evento de alteração do relógio
  static async changeClock() {
    const locker_uuid = localStorage.getItem(KEYS.LOCKER_UUID);
    await api.post(`${URLSLOCALDB.CHANGE_CLOCK}`, {
      locker_uuid,
    });
  }

  // Registra o evento de equipamento disponível
  static async equipmentAvailable() {
    const locker_uuid = localStorage.getItem(KEYS.LOCKER_UUID);
    await api.post(`${URLSLOCALDB.EQUIPMENT_AVAILABLE}`, {
      locker_uuid,
    });
  }

  // Registra o evento de Falha no abastecimento
  static async supplyFailure(
    allocation_uuid,
  ) {
    const registration_code = localStorage.getItem(KEYS.CORREIOS_SEPARATOR_REGISTRATION);
    const locker_uuid = localStorage.getItem(KEYS.LOCKER_UUID);
    await api.post(`${URLSLOCALDB.SUPPLY_FAILURE}`, {
      locker_uuid,
      registration_code,
      allocation_uuid,
    });
  }

  // Registra o evento de desbloqueio de compartimento por falha no abastecimento
  static async unblockCompartmentsByFailure(
    container_uuid,
    allocation_uuid,
  ) {
    const registration_code = localStorage.getItem(KEYS.CORREIOS_SEPARATOR_REGISTRATION);
    const locker_uuid = localStorage.getItem(KEYS.LOCKER_UUID);
    await api.post(`${URLSLOCALDB.UNBLOCK_COMPARTMENTS_BY_FAILURE}`, {
      locker_uuid,
      registration_code,
      container_uuid,
      allocation_uuid,
    });
  }

  // Define token para job de sincronia
  static async authToken() {
    const token = localStorage.getItem(KEYS.LOCKER_TOKEN);
    const locker_uuid = localStorage.getItem(KEYS.LOCKER_UUID);
    await api.post(`${URLSLOCALDB.TOKEN}`, {
      token,
      locker_uuid,
    });
  }

  
}

const api = axios.create({
  baseURL: config.urllocaldb,
});


api.interceptors.request.use(
  (requestConfig) => {
    requestConfig.headers = {
      "Content-Type": requestConfig.data instanceof FormData ? "multipart/form-data; charset=utf-8" : "application/json; charset=utf-8",
      accept: "*/*",
    };
    requestConfig.headers['X-Event-Secret'] = config.secretlocaldb;
    return requestConfig;
  },
  (error) => Promise.reject(error)
);
