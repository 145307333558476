import React, { useState } from "react";

/**
 * @Name: Botão Padrão
 * @Data: 2019
 * @Desc: Botão Padrão do Sistema.
 * @props:
 *     state: 'estado do botão (primario, alerta, etc...)'
 *     isAction: 'define se o botão é uma ação especial'
 *     small: 'define se o botão é pequeno'
 *     disabled: 'define se o botão está desabilitado'
 *     animate: 'define se o botão deve ter animação'
 *     children: 'conteúdo do botão'
 *     onClick: 'função a ser chamada ao clicar no botão'
 *     hidden: 'define se o botão está oculto'
 *     type: 'tipo do botão (button, submit, etc...)'
 *     loading: 'define se o botão está em estado de carregamento'
 *     icon: 'ícone a ser exibido no botão'
 *     show: 'define se o botão deve ser exibido'
 */

const ButtonComponent = ({
  state = "secondary",
  isAction = false,
  small = false,
  disabled = false,
  animate = false,
  children,
  onClick = null,
  hidden = false,
  type = "button",
  loading = false,
  icon,
  show = true,
  disabledColor = false,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClick = async (e) => {
    if (onClick && !loading && !isProcessing) {
      setIsProcessing(true);
      try {
        await onClick(e);
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const buttonClass = `button ${state ? "button-" + state : "button-secondary"}${isAction ? " button-action" : ""
    }${small ? " button-sm" : ""}${disabled ? " disabled" : ""}${disabledColor ? " button-disabled" : ""}${animate ? " animate" : ""}`;


  return (
    show && (
      <button
        className={buttonClass}
        onClick={handleClick}
        hidden={hidden}
        type={type}
        disabled={disabled || isProcessing}
      >
        {loading ? (
          <span className="mini-loading">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </span>
        ) : icon ? (
          <>
            <div>
              <i className={icon} />
            </div>{" "}
            {children}
          </>
        ) : (
          children
        )}
      </button>
    )
  );
};

// Memoize with custom comparison
export default React.memo(ButtonComponent, (prevProps, nextProps) => {
  return (
    prevProps.state === nextProps.state &&
    prevProps.loading === nextProps.loading &&
    prevProps.children === nextProps.children
  );
});
