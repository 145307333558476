import BaseModel from "./BaseModel";

class ContainerTypeModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.logo = data.logo;
    this.name = data.name;
    this.isScreen = data.isScreen;
    this.hasAvailableContainers = data.availableCount > 0;
    this.availableCount = data.availableCount;
  }
}

export default ContainerTypeModel;
